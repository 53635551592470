import { dealDocumentAtom } from "@/atoms/deal";
import { Deal } from "@/hooks/useDeals";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";

export const useDealTextInput = (path: keyof Deal) => {
  const [[deal], setDeal] = useAtom(dealDocumentAtom);
  const [value, setValue] = useState<string | number>(
    deal?.[path] as string | number,
  );

  useEffect(() => {
    setValue(deal?.[path] as string | number);
  }, [deal, path]);

  return {
    flex: "1",
    value,
    onChange: async (
      e: React.ChangeEvent<HTMLInputElement> | string | number,
    ) => {
      let newValue: string | number;

      if (typeof e === "string" || typeof e === "number") {
        newValue = e;
      } else {
        newValue = (e as React.ChangeEvent<HTMLInputElement>).currentTarget
          .value;
      }

      setValue(newValue);
      setDeal({ ...deal, [path]: newValue }, { merge: true });
    },
  };
};
