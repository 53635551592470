import { coreModelAtom, modelAtom } from "@/financeModels/owasco/model";
import { collectionRef } from "@/hooks/useModels";
import { Route } from "@/paths";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { matchPath } from "react-router-dom";
import { PersistableModel } from "./types";

const saveRevisionInterval = 40;
let revisionSaveCounter = 0;

const getModelId = () => {
  return matchPath({ path: Route.Model, end: false }, location.pathname)?.params
    .modelId;
};

export const lastSaveAttemptIdAtom = atom("");
export const lastSaveCompletedIdAtom = atom("");
export const lastSaveErrorIdAtom = atom("");

export const isSavingAtom = atom((get) => {
  return get(lastSaveAttemptIdAtom) !== get(lastSaveCompletedIdAtom);
});

export const saveErrorAtom = atom((get) => {
  return get(lastSaveAttemptIdAtom) === get(lastSaveErrorIdAtom);
});

let hasSetLoadedModel = false;
const loadedModelFromFirebaseAtom = atom<PersistableModel | null>(null);
loadedModelFromFirebaseAtom.onMount = (setAtom) => {
  hasSetLoadedModel = false;
  const docRef = doc(collectionRef, getModelId(), "revision", "latest");
  getDoc(docRef)
    .then((doc) => {
      const data = doc.data();
      setAtom(data as PersistableModel);
    })
    .catch(() => {
      alert(
        "Error loading model. You may not have access to this model, it may have been deleted, or it may not exist.",
      );
    });
};

export const saveToFirebaseEffect = atomEffect((get, set) => {
  const model = get(coreModelAtom);
  const modelId = getModelId();
  const modelRef = doc(collectionRef, modelId);
  const modelDocRef = doc(modelRef, "revision", "latest");

  // Load the model from firebase on mount
  if (get(loadedModelFromFirebaseAtom) === null) return;
  if (!hasSetLoadedModel) {
    set(modelAtom, get(loadedModelFromFirebaseAtom) || model);
    hasSetLoadedModel = true;
    return;
  }

  const saveActionId = Math.random().toString(36).slice(-5);
  set(lastSaveAttemptIdAtom, saveActionId);

  //console.log("Saving model to firebase...");
  setDoc(
    modelDocRef,
    { ...model, createdAt: serverTimestamp() },
    { merge: true },
  )
    .then(() => {
      set(lastSaveCompletedIdAtom, saveActionId);
      revisionSaveCounter++;

      if (revisionSaveCounter % saveRevisionInterval === 0) {
        addDoc(collection(modelRef, "revision"), {
          ...model,
          createdAt: serverTimestamp(),
        });
      }
    })
    .catch(() => {
      set(lastSaveErrorIdAtom, saveActionId);
    });
});

export const hasFinishedLoadingModelOnMountAtom = atom((get) => {
  return get(loadedModelFromFirebaseAtom) !== null;
});
