import { agGridRefAtom } from "@/atoms/dealTracker/agGrid";
import { Category, categoryPalettes } from "@/atoms/dealTracker/category";
import { dealTrackerDocumentAtom } from "@/atoms/dealTracker/dealTracker";
import { useUpdateDealTracker } from "@/hooks/useDealTracker";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import {
  Button,
  ColorPicker,
  ColorSwatch,
  Divider,
  Group,
  Popover,
  Stack,
  TextInput,
} from "@mantine/core";
import { useListState } from "@mantine/hooks";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { FaFillDrip, FaTrash } from "react-icons/fa";
import { MdDragIndicator } from "react-icons/md";

export const EditCategoryList = ({
  onUpdateComplete,
}: {
  onUpdateComplete?: () => void;
}) => {
  const gridRef = useAtomValue(agGridRefAtom);
  const [dealTracker] = useAtomValue(dealTrackerDocumentAtom);
  const [update, isUpdating] = useUpdateDealTracker();
  const [categories, handlers] = useListState(dealTracker?.categories || []);

  const onAddCategory = () => {
    handlers.append({
      id: Math.random().toString(36).slice(-6),
      label: "New Status",
      paletteId: "lipstick",
    });
  };

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) =>
        handlers.reorder({ from: source.index, to: destination?.index || 0 })
      }
    >
      <Stack gap={"xs"}>
        <Droppable droppableId="dnd-list" direction="vertical">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {categories.map((category, index) => (
                <Draggable
                  key={category.id}
                  index={index}
                  draggableId={category.id}
                >
                  {(provided) => (
                    <Group
                      gap={0}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <div {...provided.dragHandleProps}>
                        <MdDragIndicator
                          style={{
                            position: "relative",
                            left: "-8px",
                            top: "2px",
                            cursor: "grab",
                          }}
                        />
                      </div>
                      <EditCategory
                        category={category}
                        setCategory={(category: Category) => {
                          handlers.setItem(index, category);
                        }}
                        remove={() => {
                          handlers.remove(index);
                        }}
                      />
                    </Group>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Stack gap={"xs"}>
          <Button
            size="sm"
            fullWidth
            variant="outline"
            color="gray"
            onClick={onAddCategory}
          >
            Add Status
          </Button>
          <Divider />
          <Button
            fullWidth
            onClick={async () => {
              await update({ categories });
              gridRef?.current?.api.onSortChanged();
              onUpdateComplete?.();
            }}
            loading={isUpdating}
          >
            Apply Changes
          </Button>
        </Stack>
      </Stack>
    </DragDropContext>
  );
};

const EditCategory = ({
  category,
  setCategory,
  remove,
}: {
  category: Category;
  setCategory: (category: Category) => void;
  remove: () => void;
}) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const palette = categoryPalettes.find(
    (color) => color.id === category.paletteId,
  );

  return (
    <Group key={category.id} gap={"xs"} flex={1} pb={4}>
      <Popover
        trapFocus
        width={240}
        position="bottom"
        withArrow
        shadow="md"
        withinPortal={false}
        opened={showColorPicker}
      >
        <Popover.Target>
          <ColorSwatch
            color={
              categoryPalettes.find((color) => color.id === category.paletteId)
                ?.backgroundColor || "#bada55"
            }
            radius={4}
            onClick={() => setShowColorPicker(true)}
          >
            <FaFillDrip color={palette?.fontColor} />
          </ColorSwatch>
        </Popover.Target>
        <Popover.Dropdown>
          <ColorPicker
            format="hex"
            value={"#009790"}
            onChange={() => {}}
            withPicker={false}
            fullWidth
            swatches={[...categoryPalettes]
              .slice(1, 99)
              .map((color) => color.backgroundColor)}
            onColorSwatchClick={(color) => {
              setShowColorPicker(false);
              setCategory({
                ...category,
                paletteId:
                  categoryPalettes.find(
                    (catColor) =>
                      catColor.backgroundColor.toLowerCase() ===
                      color.toLowerCase(),
                  )?.id || "gray",
              });
            }}
          />
        </Popover.Dropdown>
      </Popover>

      <TextInput
        flex={1}
        value={category.label}
        onChange={(event) => {
          setCategory({ ...category, label: event.currentTarget.value });
        }}
        size="xs"
      />

      <FaTrash
        cursor={"pointer"}
        color={"var(--mantine-color-gray-5"}
        onClick={remove}
      />
    </Group>
  );
};
