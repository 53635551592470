import {
  Anchor,
  Divider,
  Drawer,
  Group,
  ScrollArea,
  Title,
  TypographyStylesProvider,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { ReactNode, Suspense } from "react";
import { IoSchool } from "react-icons/io5";
import { RiQuestionLine } from "react-icons/ri";
import { css } from "vite-plugin-inline-css-modules";

const classes = css`
  .root {
    h1,
    h2,
    h3 {
      margin-top: 0px !important;
    }
  }
`;

export const LabelExplainer = ({
  label,
  explanation,
  preventDefault,
  showIcon = true,
}: {
  explanation: ReactNode | string;
  label: ReactNode | string;
  preventDefault?: boolean;
  showIcon?: boolean;
}) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Drawer
        opened={opened}
        onClose={close}
        position="right"
        title={
          <Group justify="center" align="center" pl="sm">
            <IoSchool size={30} />
            <Title order={3} pt={4}>
              Learning Center
            </Title>
          </Group>
        }
        offset={8}
        padding={0}
        styles={{
          close: {
            marginRight: 6,
          },
        }}
      >
        <Divider mb={"md"} />
        <ScrollArea h={"calc(100vh - 130px)"} pl={"sm"} offsetScrollbars>
          <TypographyStylesProvider p={0} classNames={classes}>
            <Suspense fallback={"Loading"}>{explanation}</Suspense>
          </TypographyStylesProvider>
        </ScrollArea>
      </Drawer>
      <Anchor
        underline="always"
        inherit
        onClick={(e) => {
          if (preventDefault) e.preventDefault();
          open();
        }}
        style={{
          textDecorationStyle: "dotted",
          verticalAlign: "top",
          textDecorationThickness: 1,
          textDecorationColor: "var(--mantine-color-accent-5)",
        }}
      >
        {label} {showIcon && <RiQuestionLine />}
      </Anchor>
    </>
  );
};
