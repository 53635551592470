import {
  addbacksAndAdjustmentsItemization,
  amortOfDealExpensesMolecule,
  annualDeprAmortizationExpensesMolecule,
  annualMaintenanceCapExMolecule,
  buyersAdjustedEBITDAMarginsMolecule,
  buyersAdjustedEBITDAMolecule,
  buyersCompensationMolecule,
  companysTaxRateMolecule,
  costOfGoodsSoldItemization,
  distributableFreeCashFlowMolecule,
  grossMarginsMolecule,
  grossProfitMolecule,
  grossRevenueItemization,
  incomeTaxesMolecule,
  lineOfCreditInterestMolecule,
  lineOfCreditPricipalMolecule,
  netIncomeMolecule,
  newOperatingExpensesItemization,
  operatingExpensesItemization,
  otherIncomeMolecule,
  sba7aLoanExpenseInterestMolecule,
  sba7aLoanExpensePricipalMolecule,
  sba7aLoanExpenseTotalMolecule,
  sellersDeprAmortMolecule,
  sellersDiscretionaryEarningsMarginsMolecule,
  sellersDiscretionaryEarningsMolecule,
  sellersEBITDAMolecule,
  sellersIncomeTaxMolecule,
  sellersInterestExpenseMolecule,
  sellersNoteExpenseInterestMolecule,
  sellersNoteExpensePricipalMolecule,
  sellersNoteExpenseTotalMolecule,
  taxableIncomeMolecule,
  totalDebtServiceMolecule,
  unleveredFreeCashFlowMolecule,
} from "@/financeModels/owasco/lookback";
import { Badge, Divider } from "@mantine/core";
import { CellRow } from "../CellGrid/CellRow";
import { ComputedLabel } from "../CellGrid/ComputedLabel";
import { Grid } from "../CellGrid/Grid";
import { Itemization } from "../CellGrid/Itemization";
import { TitleRow } from "../CellGrid/TitleRow";
import { Waterfall } from "../CellGrid/Waterfall";
import { EpochRow } from "./EpochRow";

export const PanelHistoricals = () => {
  let tabYIndex = 0;

  return (
    <Grid w={"100%"} h={"100%"} pos={"relative"}>
      <EpochRow />
      <TitleRow>Company Financials</TitleRow>

      <Waterfall>
        <Itemization
          itemizationAtom={grossRevenueItemization}
          label={<Waterfall.Dot variant="cap">Gross Revenue</Waterfall.Dot>}
          tabY={tabYIndex++}
        />
        <Itemization
          itemizationAtom={costOfGoodsSoldItemization}
          label={
            <Waterfall.Dot variant="minus">
              Cost of Goods Sold (COGS)
            </Waterfall.Dot>
          }
          tabY={tabYIndex++}
        />
        <CellRow
          tabY={tabYIndex++}
          molecule={grossProfitMolecule}
          label={
            <Waterfall.Dot variant="equals">
              <Waterfall.Formula>Gross Profit</Waterfall.Formula>
            </Waterfall.Dot>
          }
        />
        <CellRow
          variant="transparent"
          labelWidth={140}
          tabY={tabYIndex++}
          molecule={grossMarginsMolecule}
          label={
            <ComputedLabel
              tooltip={
                <>
                  = <Badge color="blue">Gross Profit</Badge>/
                  <Badge color="blue">Cost Of Goods</Badge>
                </>
              }
            >
              Gross Margins
            </ComputedLabel>
          }
        />
        <Itemization
          itemizationAtom={operatingExpensesItemization}
          label={
            <Waterfall.Dot variant="minus">Operating Expenses</Waterfall.Dot>
          }
          tabY={tabYIndex++}
        />
        <CellRow
          molecule={otherIncomeMolecule}
          label={<Waterfall.Dot>Other Income</Waterfall.Dot>}
          tabY={tabYIndex++}
        />
        <CellRow
          tabY={tabYIndex++}
          molecule={netIncomeMolecule}
          label={
            <Waterfall.Dot variant="equals">
              <Waterfall.Formula>Net Income</Waterfall.Formula>
            </Waterfall.Dot>
          }
        />
      </Waterfall>

      <TitleRow children="Seller's Financial Adjustments" />
      <Waterfall>
        <Waterfall.Stub>
          <Waterfall.Dot variant="cap">
            <Waterfall.Pill>Net Income</Waterfall.Pill>
          </Waterfall.Dot>
        </Waterfall.Stub>
        <CellRow
          tabY={tabYIndex++}
          molecule={sellersInterestExpenseMolecule}
          label={
            <Waterfall.Dot variant="add">
              Seller's Interest Expenses
            </Waterfall.Dot>
          }
        />
        <CellRow
          tabY={tabYIndex++}
          molecule={sellersDeprAmortMolecule}
          label={
            <Waterfall.Dot>Seller's Depreciation & Amortization</Waterfall.Dot>
          }
        />
        <CellRow
          tabY={tabYIndex++}
          molecule={sellersIncomeTaxMolecule}
          label={<Waterfall.Dot>Seller's Income Taxes</Waterfall.Dot>}
        />

        <CellRow
          tabY={tabYIndex++}
          molecule={sellersEBITDAMolecule}
          label={
            <Waterfall.Dot variant="equals">
              <Waterfall.Formula>Seller's EBITDA</Waterfall.Formula>
            </Waterfall.Dot>
          }
        />

        <Itemization
          label={<Waterfall.Dot>Seller's Addbacks</Waterfall.Dot>}
          itemizationAtom={addbacksAndAdjustmentsItemization}
          tabY={tabYIndex++}
        />

        <CellRow
          tabY={tabYIndex++}
          molecule={sellersDiscretionaryEarningsMolecule}
          label={
            <Waterfall.Dot variant="equals">
              <Waterfall.Formula>
                Seller's Adjusted EBITDA (SDE)
              </Waterfall.Formula>
            </Waterfall.Dot>
          }
        />
      </Waterfall>
      <CellRow
        variant="transparent"
        labelWidth={260}
        tabY={tabYIndex++}
        molecule={sellersDiscretionaryEarningsMarginsMolecule}
        label={
          <ComputedLabel
            tooltip={
              <>
                = <Badge color="blue">Seller's Adjusted EBITDA</Badge>/
                <Badge color="blue">Gross Revenue</Badge>
              </>
            }
          >
            Seller's Adjusted EBITDA Margins
          </ComputedLabel>
        }
      />

      <TitleRow children="Buyer's Pro-Forma Adjustments" />
      <Waterfall>
        <Waterfall.Stub>
          <Waterfall.Dot variant="cap">
            <Waterfall.Pill>Seller's Adjusted EBITDA (SDE)</Waterfall.Pill>
          </Waterfall.Dot>
        </Waterfall.Stub>
        <CellRow
          tabY={tabYIndex++}
          molecule={buyersCompensationMolecule}
          label={
            <Waterfall.Dot variant="minus">Buyer's Compensation</Waterfall.Dot>
          }
        />
        <Itemization
          itemizationAtom={newOperatingExpensesItemization}
          label={
            <Waterfall.Dot variant="minus">
              New Operating Expenses
            </Waterfall.Dot>
          }
          tabY={tabYIndex++}
        />
        <CellRow
          tabY={tabYIndex++}
          molecule={buyersAdjustedEBITDAMolecule}
          label={
            <Waterfall.Dot variant="equals">
              <Waterfall.Formula>Buyer's Adjusted EBITDA</Waterfall.Formula>
            </Waterfall.Dot>
          }
        />
      </Waterfall>
      <CellRow
        labelWidth={265}
        variant="transparent"
        tabY={tabYIndex++}
        molecule={buyersAdjustedEBITDAMarginsMolecule}
        label={
          <ComputedLabel
            tooltip={
              <>
                = <Badge color="blue">Buyer's Adjusted EBITDA</Badge>/
                <Badge color="blue">Gross Revenue</Badge>
              </>
            }
          >
            Buyer's Adjusted EBITDA Margins
          </ComputedLabel>
        }
      />

      <TitleRow children="Cash Flow Metrics & Distributions" />

      <Waterfall.Collapse
        title={"Unlevered Free Cash Flow"}
        tabY={tabYIndex++}
        molecule={unleveredFreeCashFlowMolecule}
        isRoot
      >
        <Waterfall.Stub>
          <Waterfall.Dot variant="cap">
            <Waterfall.Pill>Buyer's Adjusted EBITDA</Waterfall.Pill>
          </Waterfall.Dot>
        </Waterfall.Stub>

        <Waterfall.Collapse
          title={"Income Taxes"}
          tabY={tabYIndex++}
          molecule={incomeTaxesMolecule}
          dotVariant="minus"
        >
          <Waterfall.Stub>
            <Waterfall.Dot variant="cap">
              <Waterfall.Pill>Buyer's Adjusted EBITDA</Waterfall.Pill>
            </Waterfall.Dot>
          </Waterfall.Stub>
          <CellRow
            tabY={tabYIndex++}
            molecule={annualMaintenanceCapExMolecule}
            label={
              <Waterfall.Dot variant="minus">
                Annual Maintenance CapEx
              </Waterfall.Dot>
            }
          />
          <CellRow
            tabY={tabYIndex++}
            molecule={sba7aLoanExpenseInterestMolecule}
            label={
              <Waterfall.Dot variant="minus">
                Interest Expense - SBA 7(a) Loan
              </Waterfall.Dot>
            }
          />
          <CellRow
            tabY={tabYIndex++}
            molecule={sellersNoteExpenseInterestMolecule}
            label={
              <Waterfall.Dot variant="minus">
                Interest Expense - Seller's Note
              </Waterfall.Dot>
            }
          />
          <CellRow
            tabY={tabYIndex++}
            molecule={annualDeprAmortizationExpensesMolecule}
            label={
              <Waterfall.Dot variant="minus">
                Depreciation & Amortization
              </Waterfall.Dot>
            }
          />
          <CellRow
            tabY={tabYIndex++}
            molecule={amortOfDealExpensesMolecule}
            label={
              <Waterfall.Dot variant="minus">
                Amortization of Deal Expenses
              </Waterfall.Dot>
            }
          />
          <CellRow
            tabY={tabYIndex++}
            molecule={taxableIncomeMolecule}
            label={<Waterfall.Formula>Taxable Income</Waterfall.Formula>}
          />
          <CellRow
            tabY={tabYIndex++}
            molecule={companysTaxRateMolecule}
            label={
              <Waterfall.Dot variant="multiply">
                Company's Tax Rate
              </Waterfall.Dot>
            }
          />
        </Waterfall.Collapse>

        <CellRow
          tabY={tabYIndex++}
          molecule={annualMaintenanceCapExMolecule}
          label={
            <Waterfall.Dot variant="minus">
              Annual Maintenance CapEx
            </Waterfall.Dot>
          }
        />
      </Waterfall.Collapse>

      <Divider mb="sm" />

      <Waterfall.Collapse
        tabY={tabYIndex++}
        molecule={distributableFreeCashFlowMolecule}
        title={"Distributable Free Cash Flow"}
        isRoot
      >
        <Waterfall.Stub>
          <Waterfall.Dot variant="cap">
            <Waterfall.Formula>Unlevered Free Cash Flow</Waterfall.Formula>
          </Waterfall.Dot>
        </Waterfall.Stub>
        <Waterfall.Collapse
          tabY={tabYIndex++}
          molecule={totalDebtServiceMolecule}
          title={"Total Debt Service"}
          dotVariant="minus"
        >
          <Waterfall.Collapse
            title={"SBA 7(a) Loan Total"}
            tabY={tabYIndex++}
            molecule={sba7aLoanExpenseTotalMolecule}
            dotVariant="add"
          >
            <CellRow
              tabY={tabYIndex++}
              molecule={sba7aLoanExpensePricipalMolecule}
              label={<Waterfall.Dot>Principal - SBA 7(a) Loan</Waterfall.Dot>}
            />
            <CellRow
              tabY={tabYIndex++}
              molecule={sba7aLoanExpenseInterestMolecule}
              label={
                <Waterfall.Dot variant="add">
                  Interest - SBA 7(a) Loan
                </Waterfall.Dot>
              }
            />
          </Waterfall.Collapse>

          <Waterfall.Collapse
            title={
              <>
                Line Of Credit{" "}
                <span
                  style={{
                    fontSize: 11,
                    paddingTop: 2,
                    verticalAlign: "bottom",
                  }}
                >
                  (Working Capital)
                </span>
              </>
            }
            tabY={tabYIndex++}
            molecule={lineOfCreditInterestMolecule}
            dotVariant="add"
          >
            <CellRow
              tabY={tabYIndex++}
              molecule={lineOfCreditPricipalMolecule}
              label={
                <Waterfall.Dot variant="add">
                  Principal - Line Of Credit
                </Waterfall.Dot>
              }
            />
            <CellRow
              tabY={tabYIndex++}
              molecule={lineOfCreditInterestMolecule}
              label={
                <Waterfall.Dot variant="add">
                  Interest - Line Of Credit
                </Waterfall.Dot>
              }
            />
          </Waterfall.Collapse>

          <Waterfall.Collapse
            title={"Seller's Note Total"}
            tabY={tabYIndex++}
            molecule={sellersNoteExpenseTotalMolecule}
            dotVariant="add"
          >
            <CellRow
              tabY={tabYIndex++}
              molecule={sellersNoteExpensePricipalMolecule}
              label={
                <Waterfall.Dot variant="add">
                  Principal - Seller's Note
                </Waterfall.Dot>
              }
            />
            <CellRow
              tabY={tabYIndex++}
              molecule={sellersNoteExpenseInterestMolecule}
              label={
                <Waterfall.Dot variant="add">
                  Interest - Seller's Note
                </Waterfall.Dot>
              }
            />
          </Waterfall.Collapse>
        </Waterfall.Collapse>
      </Waterfall.Collapse>

      <Divider my="xl" />
    </Grid>
  );
};
