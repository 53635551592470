import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import { confirmOnCloseAtom } from "@/financeModels/owasco/confirmOnClose";
import { useIsModelAnonymous } from "@/hooks/useLiteModelToFull";
import { useModel, useUpdate } from "@/hooks/useModel";
import { useModelDeal } from "@/hooks/useModelDeal";
import { Route } from "@/paths";
import {
  ScrollArea,
  Stack,
  StackProps,
  Text,
  TextInput,
  TextInputProps,
} from "@mantine/core";
import { useAtomValue } from "jotai";
import { memo } from "react";
import { BsCloudCheckFill } from "react-icons/bs";
import { generatePath } from "react-router-dom";
import { css } from "vite-plugin-inline-css-modules";
import { Accordion } from "./Accordian";
import { CollapseAllButton } from "./CollapseAllButton";

const borderOnFocusClasses = css`
  .borderOnFocus {
    input {
      border: 1px solid transparent;
      padding-left: 6px;
    }

    input:focus {
      border: 1px solid #bbb;
    }
  }
`;

export const Sidebar = memo(
  ({ ...props }: StackProps & { headerHeight?: number }) => {
    return (
      <Stack
        w={368}
        h={"100%"}
        gap={0}
        style={{
          borderRight: "1px solid #ccc",
          boxShadow: "0 0 4px rgba(0, 0, 0, 0.1)",
          background: "linear-gradient(180deg, #f1f5f9 0%, #f1f5f9 100%)",
        }}
        pos={"relative"}
        {...props}
      >
        <Header />

        <Stack
          h={"100%"}
          justify="space-between"
          gap={0}
          pos={"relative"}
          flex={1}
          style={{
            overflow: "hidden",
          }}
        >
          <ScrollArea scrollbarSize={8}>
            <Stack
              flex={1}
              gap={"xs"}
              style={{ overflow: "hidden" }}
              pt={"xs"}
              pb={100}
              pl={"xs"}
            >
              <Accordion />
            </Stack>
          </ScrollArea>
        </Stack>
        <CollapseAllButton />
      </Stack>
    );
  },
);

const Header = () => {
  const isModelAnon = useIsModelAnonymous();
  const [deal] = useModelDeal();

  const dealPath = generatePath(Route.Deal, {
    dealId: deal?.id || "missing-deal-id",
  });

  return (
    <LayoutWorkstation.Header>
      <LayoutWorkstation.Header.Breadcrumbs>
        {isModelAnon ? (
          <LayoutWorkstation.Header.BreadcrumbTitle>
            Your Model
          </LayoutWorkstation.Header.BreadcrumbTitle>
        ) : (
          <>
            <LayoutWorkstation.Header.BreadcrumbTitle to={dealPath}>
              Deal
            </LayoutWorkstation.Header.BreadcrumbTitle>
            <LayoutWorkstation.Header.BreadcrumbSeperator />
            <NameInput />
            <Status />
          </>
        )}
      </LayoutWorkstation.Header.Breadcrumbs>
    </LayoutWorkstation.Header>
  );
};

const Status = () => {
  const isSaving = useAtomValue(confirmOnCloseAtom);
  const isModelAnon = useIsModelAnonymous();

  return (
    <Stack
      ml={"auto"}
      justify="center"
      align="center"
      opacity={isModelAnon ? 0 : 1}
    >
      {isSaving && <Text size="xs">Saving...</Text>}
      {!isSaving && (
        <BsCloudCheckFill size={18} color="var(--mantine-color-gray-5)" />
      )}
    </Stack>
  );
};

export const NameInput = (props: TextInputProps) => {
  const [model] = useModel();
  const [updateModel] = useUpdate();

  return (
    <TextInput
      //leftSection={<LuFileSpreadsheet />}
      ml={-4}
      onChange={(e) => {
        updateModel({ name: e.currentTarget.value });
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") e.currentTarget.blur();
      }}
      value={model?.name || ""}
      fw={600}
      flex={1}
      placeholder="Model Name"
      variant="unstyled"
      className={borderOnFocusClasses.borderOnFocus}
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      {...props}
    />
  );
};
