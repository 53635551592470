import { dealDocumentAtom } from "@/atoms/deal";
import { DealNote, useUpdateDeal } from "@/hooks/useDeals";
import {
  ActionIcon,
  Box,
  Button,
  Card,
  Group,
  Kbd,
  Menu,
  ScrollArea,
  Spoiler,
  Stack,
  Text,
  Textarea,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { Timestamp, arrayRemove, arrayUnion } from "firebase/firestore";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import { FaPencil } from "react-icons/fa6";

export const Notes = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [text, setText] = useState("");
  const [deal] = useAtomValue(dealDocumentAtom);
  const [updateDeal] = useUpdateDeal();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await updateDeal(deal!.id, {
      // @ts-expect-error ArrayUnion is not a valid type
      notes: arrayUnion({
        id: Math.random().toString(36).substring(6),
        text,
        createdAt: Timestamp.now(),
      } satisfies DealNote),
    });

    setText("");
    setIsFocused(false);
  };

  const isOpen = text || isFocused;

  if (!deal) return null;

  return (
    <ScrollArea h={"calc(100dvh - 43px)"} w={"100%"} bg="gray.4">
      <Stack
        bg="gray.4"
        w="100%"
        style={{
          borderLeft: "1px solid #eee",
        }}
        p={"sm"}
      >
        <form onSubmit={onSubmit}>
          <Stack gap={"sm"}>
            <Text fw={600} size="sm">
              Notes
            </Text>
            <Textarea
              size="md"
              placeholder="Write a note..."
              autosize
              minRows={!isOpen ? 1 : 2}
              maxRows={!isOpen ? 1 : 4}
              onFocus={() => setIsFocused(true)}
              onBlur={() => {
                setIsFocused(false);
              }}
              value={text}
              onChange={(event) => setText(event.currentTarget.value)}
            />
            {(isFocused || text) && (
              <Group justify="space-between">
                <Button type="submit" color="blue">
                  Add Note
                </Button>
                <Box dir="ltr" opacity={0.5} pr="xs">
                  <Kbd>Tab</Kbd> + <Kbd>Enter</Kbd>
                </Box>
              </Group>
            )}
          </Stack>
        </form>

        <Stack gap={"sm"}>
          <Stack gap={"sm"}>
            {[...(deal?.notes || [])].reverse().map((note, index) => (
              <Note key={index} note={note} />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </ScrollArea>
  );
};

export const Note = ({ note }: { note: DealNote }) => {
  const [deal] = useAtomValue(dealDocumentAtom);
  const [editingText, setEditingText] = useState<null | string>(null);
  const [updateDeal] = useUpdateDeal();
  const removeNote = async (note: DealNote) => {
    await updateDeal(deal!.id, {
      // @ts-expect-error ArrayRemove is not a valid type
      notes: arrayRemove(note),
    });
  };

  const openDeleteModal = async () => {
    await modals.openConfirmModal({
      title: "Delete this note?",

      children: (
        <Text size="md">Are you sure you want to delete this note?</Text>
      ),
      labels: {
        confirm: `Delete`,
        cancel: "Cancel",
      },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        removeNote(note);
      },
    });
  };

  return (
    <Card
      shadow="xs"
      style={{
        border: "1px solid var(--mantine-color-gray-2)",
      }}
    >
      <Stack gap={"sm"}>
        {editingText !== null ? (
          <Stack gap={"sm"}>
            <Textarea
              size="md"
              placeholder="Write a note..."
              autosize
              minRows={1}
              maxRows={4}
              value={editingText}
              onChange={(event) => {
                setEditingText(event.currentTarget.value);
              }}
            />
            <Group justify="flex-end">
              <Button
                variant="outline"
                color="gray"
                onClick={() => {
                  setEditingText(null);
                }}
              >
                Cancel
              </Button>
              <Button
                color="blue"
                onClick={async () => {
                  await updateDeal(deal!.id, {
                    // @ts-expect-error ArrayUnion is not a valid type
                    notes: arrayUnion({
                      ...note,
                      text: editingText,
                    }),
                  });
                  setEditingText(null);
                }}
              >
                Save
              </Button>
            </Group>
          </Stack>
        ) : (
          <Spoiler
            maxHeight={56}
            showLabel="Show more"
            hideLabel="Hide"
            styles={{
              control: {
                width: "100%",
                //fade up to white with a gradient
                background: "linear-gradient(rgba(255, 255, 255, 0), white)",

                position: "absolute",
                top: "calc(100% - 12px)",
                height: 45,
                fontSize: 12,
                textAlign: "left",
                textTransform: "uppercase",
                textDecoration: "underline",
                padding: "20px 0px",
              },
            }}
          >
            <Text
              size="sm"
              w={"100%"}
              style={{
                wordBreak: "break-word",
              }}
            >
              {note.text}
            </Text>
          </Spoiler>
        )}

        <Group justify="flex-end" align="center">
          <Text size="xs" c="gray.7">
            {note.createdAt.toDate().toLocaleDateString()}
          </Text>

          <Menu shadow="md" width={200}>
            <Menu.Target>
              <ActionIcon color="gray.7" variant="transparent" size={"xs"}>
                <BsThreeDots size={12} />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                leftSection={<FaPencil />}
                onClick={() => setEditingText(note.text)}
              >
                Edit
              </Menu.Item>
              <Menu.Item
                c={"red.7"}
                leftSection={<FaTrash />}
                onClick={() => openDeleteModal()}
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Stack>
    </Card>
  );
};
