import { ZIndexOrder } from "@/theme";
import { BoxComponentProps, Stack } from "@mantine/core";
import { ReactNode } from "react";
import { Column } from "./Column";
import { lineLeftBorderStyles } from "./Waterfall";

export const defaultLabelColumnWidth = 300;

export const LabelColumn = ({
  children,
  w = defaultLabelColumnWidth,
  backdropColor = "transparent",
  ...props
}: BoxComponentProps & {
  children: ReactNode;
  backdropColor?: string;
}) => {
  return (
    <Column
      w={defaultLabelColumnWidth}
      pos={"sticky"}
      left={0}
      style={{
        alignSelf: "stretch",
        zIndex: ZIndexOrder.CellGridStickyLabels,
        backgroundColor: backdropColor,
        ...lineLeftBorderStyles,
      }}
      align="flex-end"
      className={"label-column-sticky"}
    >
      <Stack
        justify="center"
        w={w}
        h={"100%"}
        pl={8}
        pr={8}
        fz={"sm"}
        className={"label-column"}
        style={{
          boxShadow:
            "0 2px 4px 0 rgba(0, 0, 0, 0.1),inset -1px 0 0 #ebebee, inset 0 1px 0 #ebebee ",

          ...(w === defaultLabelColumnWidth ? lineLeftBorderStyles : {}),
        }}
        {...props}
      >
        {children}
      </Stack>
    </Column>
  );
};
