import {
  Group,
  Switch as MantineSwitch,
  SwitchProps,
  Text,
} from "@mantine/core";
import { SidebarInputProps } from "./types";

export const InputToggle = ({
  labelWidth = 228,
  label,
  ...props
}: SidebarInputProps & SwitchProps) => {
  return (
    <Group h={36}>
      <Text
        style={{
          fontWeight: 500,
          fontSize: 14,
        }}
        size={"md"}
        w={labelWidth}
        mr={"auto"}
      >
        {label}
      </Text>
      <MantineSwitch
        labelPosition="left"
        size="md"
        {...props}
        label={
          <div
            style={{
              width: 0,
              height: 0,
              overflow: "hidden",
              position: "absolute",
              left: -9999,
            }}
          >
            {label}
          </div>
        }
      />
    </Group>
  );
};
