import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import {
  businessAcquisitionCostAtom,
  isRealEstateIncludedAtom,
  isRollinDealExpensesAtom,
  lenderLineOfCreditTotalAmountAtom,
  loanTransactionFeeAtom,
  realEstateDollarValueAtom,
  sbaGuarantyFeeAtom,
  targetPurchasePriceAtom,
  totalDealExpensesAtom,
} from "@/financeModels/owasco/general";
import { MathResult } from "@/financeModels/types";
import { monospaceFontFamily } from "@/theme";
import { formatDecimalPercent, formatDollar } from "@/utils/format";
import { Table } from "@mantine/core";
import { useAtomValue } from "jotai";

export const Uses = () => {
  const targetPurchasePrice = useAtomValue(targetPurchasePriceAtom);
  const dealExpenses = useAtomValue(totalDealExpensesAtom);
  const loanTransactionFee = useAtomValue(loanTransactionFeeAtom);
  const sbaguarantyFee = useAtomValue(sbaGuarantyFeeAtom);
  const isRealEstateIncluded = useAtomValue(isRealEstateIncludedAtom);
  const realEstateDollarValue = useAtomValue(realEstateDollarValueAtom);
  const lineOfCredit = useAtomValue(lenderLineOfCreditTotalAmountAtom);
  const isRollinDealExpenses = useAtomValue(isRollinDealExpensesAtom);
  const businessAcquisitionCost = useAtomValue(businessAcquisitionCostAtom);

  type Item = [string, MathResult];

  const items = [
    ...(isRealEstateIncluded
      ? [
          ["Business Acquisition", businessAcquisitionCost],
          ["Real Estate Transaction", realEstateDollarValue],
        ]
      : [["Business Acquisition", targetPurchasePrice]]),

    ...(isRollinDealExpenses ? [["Deal Expenses", dealExpenses] as Item] : []),

    ["Loan Transaction Fee", loanTransactionFee],
    ["SBA Guaranty Fee", sbaguarantyFee],
  ];

  if (lineOfCredit)
    items.push(["Line of Credit (Working Capital)", lineOfCredit]);

  const totalProjectCost = items.reduce(
    (acc, [, value]) => acc + Number(value),
    0,
  );

  return (
    <LayoutWorkstation.Panel p={0}>
      <Table striped>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Uses</Table.Th>
            <Table.Th ta={"right"} pr={30}>
              Amount
            </Table.Th>
            <Table.Th w={"10%"}></Table.Th>
            <Table.Th ta={"right"} w={100}>
              % of Total
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {items.map((item) => (
            <Table.Tr key={item[0] as string}>
              <Table.Td>{item[0] as string}</Table.Td>
              <Table.Td ta={"right"} w={160} ff={monospaceFontFamily}>
                {formatDollar(Number(item[1]))}
              </Table.Td>
              <Table.Td></Table.Td>
              <Table.Td ff={monospaceFontFamily}>
                {formatDecimalPercent(
                  Number(item[1]) / Number(totalProjectCost),
                )}
              </Table.Td>
            </Table.Tr>
          ))}

          <Table.Tr fw={700}>
            <Table.Td>Total Project Cost</Table.Td>
            <Table.Td ta={"right"} w={160} ff={monospaceFontFamily}>
              {formatDollar(totalProjectCost)}
            </Table.Td>
            <Table.Td></Table.Td>
            <Table.Td fw={700} ff={monospaceFontFamily}>
              100%
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </LayoutWorkstation.Panel>
  );
};
