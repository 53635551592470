import { Atom, Getter, PrimitiveAtom, atom } from "jotai";
import { focusAtom } from "jotai-optics";
import { sbaLoanAmortizationScheduleAtom } from "./amortization";
import { createStorableCellAtom } from "./cell";
import { createFocusAtom } from "./general";
import { createItemizationAtom } from "./itemization";
import { modelAtom } from "./model";
import { createOverridableCellAtom } from "./overridable";
import { CellTypes, DollarCell } from "./types";

export const iterationCountAtom = focusAtom(modelAtom, (optic) =>
  optic.prop("general").prop("iterationCount").valueOr(0),
);

export const iterationInitialYearAtom = createFocusAtom(
  "iterationInitialYear",
  0,
);

export const suggestedIterationCountAtom = atom((get) => {
  return Math.ceil(get(sbaLoanAmortizationScheduleAtom));
});

export const createIterationMolecule = (
  initialAtom: PrimitiveAtom<CellTypes>,
  percentGrowthCellAtom: PrimitiveAtom<CellTypes>,
  fn: (
    get: Getter,
    index: number,
    prevAtoms: PrimitiveAtom<CellTypes>[],
  ) => PrimitiveAtom<CellTypes>,
) => {
  return atom((get) => {
    const iterationCount = get(iterationCountAtom) || 0;
    const prevAtoms: PrimitiveAtom<CellTypes>[] = [];
    return [
      initialAtom,
      percentGrowthCellAtom,
      ...Array.from({ length: iterationCount }, (_, i) => {
        prevAtoms.push(fn(get, i, prevAtoms));
        return prevAtoms[i];
      }),
    ];
  });
};

export const createGrowthRateCellAtom = (id: string, value: number = 0.05) => {
  return createStorableCellAtom(`projection|growthRate|${id}`, {
    type: "growth",
    value,
  });
};

export const createGrowthRateIterationMolecule = (
  id: string,
  initialAtom: PrimitiveAtom<CellTypes>,
  percentGrowthCellAtom: PrimitiveAtom<CellTypes>,
) => {
  const cache: Record<number, PrimitiveAtom<CellTypes>> = {};

  return atom((get) => {
    const iterationCount = get(iterationCountAtom) || 0;
    return [
      initialAtom,
      percentGrowthCellAtom,
      ...Array.from({ length: iterationCount }, (_, index) => {
        if (cache[index]) return cache[index];

        const newCellAtom = atom((get) => {
          const prevAtom = index === 0 ? initialAtom : cache[index - 1];

          const newValue =
            Number(get(prevAtom).value) *
            (1 + Number(get(percentGrowthCellAtom).value));

          return {
            ...get(prevAtom),
            value: newValue,
          } as CellTypes;
        });

        const o = createOverridableCellAtom(
          `projection|iteration|${id}|${index}`,
          newCellAtom,
        );

        cache[index] = o;

        return o;
      }),
    ];
  });
};

export const createAggregatedIterationAtom = (
  id: string,
  options?: { isNegative: boolean },
) => {
  return createItemizationAtom({
    id,
    consolidatedMolecule: createGrowthRateIterationMolecule(
      `itemizationIteration|${id}|consolidated`,
      createStorableCellAtom(
        `itemizationIterationFirstYearCell|${id}|consolidated`,
        {
          type: "dollar",
          value: 0,
          isNegative: options?.isNegative || false,
        },
      ),
      createGrowthRateCellAtom(id),
    ),
    lineItemToMolecule: (lineItem) =>
      createGrowthRateIterationMolecule(
        `itemizationIteration|${id}|lineItem|${lineItem.id}`,
        createStorableCellAtom(
          `itemizationIterationFirstYearCell|${id}|lineItem|${lineItem.id}`,
          {
            type: "dollar",
            value: 0,
          },
        ),
        createGrowthRateCellAtom(
          `itemizationIteration|${id}|lineItem|${lineItem.id}`,
        ),
      ),
    lineItemColumnToCellAtom: (cells, index) =>
      atom((get) => {
        if (index === 1)
          return {
            value: null,
            isLocked: true,
            type: "growth",
            isBlank: true,
          } as CellTypes;

        const values = cells.map((cell) => Number(get(cell).value || 0));
        const sum = values.reduce((acc, value) => acc + value, 0);
        return {
          type: "dollar",
          value: sum || 0,
          isNegative: options?.isNegative || false,
          isLocked: true,
        } as DollarCell;
      }),
  });
};

const blankGrowthCell = atom({
  value: null,
  isLocked: true,
  type: "growth",
  isBlank: true,
} as CellTypes);

export const createComputedIterationMolecule = (
  fn: (
    index: number,
    iterationIndex: number,
    prev: Atom<CellTypes>[],
  ) => Atom<CellTypes>,
  effectAtom?: PrimitiveAtom<CellTypes>,
) => {
  const prevAtoms: Atom<CellTypes>[] = [];
  const cache: Record<number, Atom<CellTypes>> = {};

  return atom((get) => {
    const iterationCount = get(iterationCountAtom) || 0;

    return Array.from({ length: iterationCount + 2 }, (_, i) => {
      if (cache[i]) return cache[i];

      const iterationIndex = i === 0 || i === 1 ? i : i - 1;
      const newAtom =
        i !== 1
          ? fn(i, iterationIndex, prevAtoms)
          : effectAtom || blankGrowthCell;

      prevAtoms.push(newAtom);
      cache[i] = newAtom;
      return newAtom;
    });
  });
};

export const createStorableDollarIterationMolecule = (
  id: string,
  initialCellProps: Partial<{ isLocked: boolean; isNegative: boolean }> = {},
  laterCellProps: Partial<{ isLocked: boolean; isNegative: boolean }> = {},
) => {
  return createIterationMolecule(
    createStorableCellAtom(`${id}|startiteration|0`, {
      type: "dollar",
      value: null,
      ...initialCellProps,
    }),
    blankGrowthCell,
    (_get, index) =>
      createStorableCellAtom(`${id}|iteration|${index}`, {
        type: "dollar",
        value: null,
        ...laterCellProps,
      }),
  );
};
