import { NumberInput, NumberInputProps } from "@mantine/core";
import parseMoney from "parse-money";
import { useState } from "react";

export const BigDollarInput = ({
  textAlign,
  ...props
}: NumberInputProps & {
  onChange?: (value: number | string) => void;
  textAlign?: "left" | "center" | "right";
}) => {
  const [displayValue, setDisplayValue] = useState<string | number | null>(
    null,
  );

  return (
    <NumberInput
      placeholder="$"
      thousandSeparator=","
      prefix="$"
      allowNegative={false}
      decimalScale={2}
      hideControls
      size="lg"
      {...props}
      value={displayValue !== null ? displayValue : props.value}
      onChange={(value) => {
        if (!props.onChange) return;
        setDisplayValue(value + "");
        const parsedValue = parseMoney(value + "")?.amount || 0;
        props.onChange(parsedValue);
      }}
      styles={{
        ...props.styles,
        input: {
          textAlign: textAlign || "left",
        },
      }}
    />
  );
};
