import { LayoutDoc } from "@/components/LayoutDoc/LayoutDoc";
import { PageForgotPassword } from "@/components/PageForgotPassword/PageForgotPassword";
import { PageHome } from "@/components/PageHome/PageHome";
import { PageLearn } from "@/components/PageLearn/PageLearn";
import { PageLogin } from "@/components/PageLogin/PageLogin";
import { PageModel } from "@/components/PageModel/PageModel";
import { PageNotFound } from "@/components/PageNotFound/PageNotFound";
import { PageProfile } from "@/components/PageProfile/PageProfile";
import { PageSignup } from "@/components/PageSignup/PageSignup";
import { routes as docRoutes } from "@/docs/_routes";
import { Route } from "@/paths";
import React from "react";
import { Outlet } from "react-router-dom";
import { LayoutBoilerplateText } from "./components/LayoutBoilerplateText/LayoutBoilerplateText";
import { PageDeal } from "./components/PageDeal/PageDeal";
import { PageDealTracker } from "./components/PageDealTracker/PageDealTracker";
import { PageError } from "./components/PageError/PageError";
import { Main } from "./components/PageModel/Main/Main";
import { RequireAuth } from "./components/RequireAuth/RequireAuth";
import { usePageTracking } from "./hooks/usePageTracking";

const PrivacyPolicy = React.lazy(() => import("@/docs/privacy-policy.mdx"));
const TermsOfService = React.lazy(() => import("@/docs/terms-of-service.mdx"));

const PageTracker = () => {
  usePageTracking();
  return <Outlet />;
};

export const routes = [
  {
    ...(import.meta.env.PROD ? { errorElement: <PageError /> } : {}),
    element: <PageTracker />,
    children: [
      ...docRoutes.map((route) => {
        const Component = route.element;
        return {
          path: route.path,
          element: (
            <LayoutDoc>
              <Component />
            </LayoutDoc>
          ),
        };
      }),
      {
        path: Route.Home,
        element: <PageHome />,
      },
      {
        path: Route.Learn,
        element: <PageLearn />,
      },

      // Auth routes
      {
        path: Route.Login,
        element: <PageLogin />,
      },
      {
        path: Route.Signup,
        element: <PageSignup />,
      },
      {
        path: Route.ForgotPassword,
        element: <PageForgotPassword />,
      },

      {
        path: Route.Model,
        element: <PageModel />,
        children: [
          {
            path: ":modelTabId",
            element: <Main />,
          },
        ],
      },

      {
        element: <RequireAuth />,
        children: [
          {
            path: Route.Profile,
            element: <PageProfile />,
          },

          {
            path: Route.DealTracker,
            element: <PageDealTracker />,
          },
          {
            path: Route.Deal,
            element: <PageDeal />,
          },
        ],
      },

      // Legal routes
      {
        element: (
          <LayoutBoilerplateText>
            <Outlet />
          </LayoutBoilerplateText>
        ),
        children: [
          {
            path: Route.PrivacyPolicy,
            element: <PrivacyPolicy />,
          },
          {
            path: Route.TermOfService,
            element: <TermsOfService />,
          },
        ],
      },
      {
        path: Route.NotFound,
        element: <PageNotFound />,
      },
    ],
  },
];
export { Route };
