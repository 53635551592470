import { Stack, StackProps, Text } from "@mantine/core";

export const Panel = ({
  children,
  label,
  ...props
}: StackProps & {
  children: React.ReactNode;
  label?: React.ReactNode;
}) => {
  return (
    <Stack gap="xs">
      {label && (
        <Text fw={600} size="sm">
          {label}
        </Text>
      )}
      <Stack
        gap={"xs"}
        p={"sm"}
        style={{
          boxShadow:
            "rgba(9, 30, 66, 0.2) 0px 1px 1px, rgba(9, 30, 66, 0.1) 0px 0px 1px 1px",
          background: "white",
          borderRadius: "0px",
          borderBottom: "2px solid #eee",
        }}
        {...props}
      >
        {children}
      </Stack>
    </Stack>
  );
};
