import { LabelExplainer } from "@/components/LabelExplainer/LabelExplainer";
import {
  sellersNoteAmortizationScheduleAtom,
  sellersNoteHasStandbyProvisionAtom,
  sellersNoteInterestRateAtom,
  sellersNoteStandbyAccrueInterestInFullStandbyAtom,
  sellersNoteStandbyIsFullStandbyAtom,
  sellersNoteStandbyPeriodAtom,
  sellersNoteStandbyPeriodErrorMessageAtom,
} from "@/financeModels/owasco/general";
import { numberToPercentInput, percentInputToNumber } from "@/utils/format";
import { toNumberOrNull } from "@/utils/math";
import { Alert, Stack } from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import React, { ChangeEvent } from "react";
import { TbId } from "react-icons/tb";
import { InputPercent } from "./InputPercent";
import { InputSelect } from "./InputSelect";
import { InputToggle } from "./InputToggle";
import { InputYears } from "./InputYears";

const SellersNoteInterestRateExplainer = React.lazy(
  () => import("@/docs/sidebar/sellersNoteInterestRate.mdx"),
);
const SellersNoteAmortScheduleExplainer = React.lazy(
  () => import("@/docs/sidebar/sellersNoteAmortSchedule.mdx"),
);
// const SellersNoteLoanStartDate = React.lazy(
//   () => import("@/docs/sidebar/sellersNoteLoanStartDate.mdx"),
// );

const SellersNoteHasStandbyProvisionExplainer = React.lazy(
  () => import("@/docs/sidebar/sellersNoteHasStandbyProvision.mdx"),
);

const SellersNoteStandbyPeriodExplainer = React.lazy(
  () => import("@/docs/sidebar/sellersNoteStandbyPeriod.mdx"),
);

const SellersNoteStandbyIsFullStandbyExplainer = React.lazy(
  () => import("@/docs/sidebar/sellersNoteStandbyIsFullStandby.mdx"),
);

const SellersNoteStandbyAccrueInterestInFullStandbyExplainer = React.lazy(
  () =>
    import("@/docs/sidebar/sellersNoteStandbyAccrueInterestInFullStandby.mdx"),
);

const Panel = () => {
  const [interestRate, setInterestRate] = useAtom(sellersNoteInterestRateAtom);
  const [schedule, setSchedule] = useAtom(sellersNoteAmortizationScheduleAtom);
  //const [loanStartDate, setLoanStartDate] = useAtom(sellersNoteStartDateAtom);
  const [hasStandbyProvision, setHasStandbyProvision] = useAtom(
    sellersNoteHasStandbyProvisionAtom,
  );
  const [standbyPeriod, setStandbyPeriod] = useAtom(
    sellersNoteStandbyPeriodAtom,
  );
  const [isFullStandby, setIsFullStandby] = useAtom(
    sellersNoteStandbyIsFullStandbyAtom,
  );
  const [accrueInterestInFullStandby, setAccrueInterestInFullStandby] = useAtom(
    sellersNoteStandbyAccrueInterestInFullStandbyAtom,
  );

  const standbyPeriodErrorMessage = useAtomValue(
    sellersNoteStandbyPeriodErrorMessageAtom,
  );

  return (
    <Stack gap={4}>
      <InputPercent
        value={numberToPercentInput(interestRate)}
        onChange={(value) => setInterestRate(percentInputToNumber(value))}
        label={
          <LabelExplainer
            label="Interest Rate"
            explanation={<SellersNoteInterestRateExplainer />}
          />
        }
      />
      <InputYears
        value={toNumberOrNull(schedule) || ""}
        onChange={(value) => setSchedule(toNumberOrNull(value))}
        label={
          <LabelExplainer
            label="Amortization Schedule"
            explanation={<SellersNoteAmortScheduleExplainer />}
          />
        }
      />
      {/* <InputDate
        value={loanStartDate.toDate()}
        onChange={(e) => setLoanStartDate(Timestamp.fromDate(e as Date))}
        label={
          <LabelExplainer
            label="Loan Start Date"
            explanation={<SellersNoteLoanStartDate />}
          />
        }
      /> */}

      <InputToggle
        label={
          <LabelExplainer
            label="Standby Provision"
            explanation={<SellersNoteHasStandbyProvisionExplainer />}
          />
        }
        checked={hasStandbyProvision}
        onChange={(event) =>
          setHasStandbyProvision(
            (event as ChangeEvent<HTMLInputElement>).currentTarget.checked,
          )
        }
      />

      {hasStandbyProvision && (
        <>
          <InputYears
            value={Number(standbyPeriod) || 1}
            onChange={(value) => setStandbyPeriod(Number(value))}
            label={
              <LabelExplainer
                label="Standby Period"
                explanation={<SellersNoteStandbyPeriodExplainer />}
              />
            }
          />
          {standbyPeriodErrorMessage && (
            <Alert variant="light" color="red" ta={"center"}>
              {standbyPeriodErrorMessage}
            </Alert>
          )}
          <InputSelect
            labelWidth={130}
            label={
              <LabelExplainer
                label="Standby Type"
                explanation={<SellersNoteStandbyIsFullStandbyExplainer />}
              />
            }
            data={[
              { value: "full", label: "Full Standby" },
              { value: "partial", label: "Partial Standby (I/O) " },
            ]}
            value={isFullStandby ? "full" : "partial"}
            onChange={(value) => setIsFullStandby(value === "full")}
          />
          {isFullStandby && (
            <InputToggle
              label={
                <LabelExplainer
                  label="Accrue Interest In Full Standby"
                  explanation={
                    <SellersNoteStandbyAccrueInterestInFullStandbyExplainer />
                  }
                />
              }
              checked={accrueInterestInFullStandby}
              onChange={(event) =>
                setAccrueInterestInFullStandby(
                  (event as ChangeEvent<HTMLInputElement>).currentTarget
                    .checked,
                )
              }
            />
          )}
        </>
      )}
    </Stack>
  );
};

export const ModuleSellersNote = {
  key: "Seller's Note",
  icon: <TbId />,
  control: "Seller's Note",
  panel: <Panel />,
};
