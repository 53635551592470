import { ZIndexOrder } from "@/theme";
import { BoxComponentProps, Group, Text } from "@mantine/core";
import { ReactNode } from "react";
import { defaultLabelColumnWidth } from "./LabelColumn";
import { lineSize } from "./Waterfall";
import { defaultHeight as defaultCellHeight } from "./cell/Wrapper";

export const TitleRow = ({
  children,
  ...props
}: BoxComponentProps & {
  children: ReactNode;
}) => {
  return (
    <Group
      w={defaultLabelColumnWidth}
      bg={"gray.3"}
      {...props}
      style={{
        zIndex: ZIndexOrder.CellGridTitleRow,
        position: "sticky",
        top: 0,
        left: 0,
      }}
      align="center"
      my={"lg"}
    >
      <Text
        h={defaultCellHeight}
        component="h1"
        fz={"md"}
        pos={"sticky"}
        left={0}
        fw={600}
        pl="xs"
        style={{
          zIndex: ZIndexOrder.CellGridTitleRow,
          position: "sticky",
          top: 0,
          borderLeft: `${lineSize}px solid #9ED4C8`,
          whiteSpace: "nowrap",
        }}
        pt={6}
      >
        {children}
      </Text>
    </Group>
  );
};
