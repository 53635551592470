import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import {
  equityDollarAtom,
  isRollinLoanTransactionFeeAtom,
  isRollinSBAGuarantyFeeAtom,
  lenderLineOfCreditTotalAmountAtom,
  loanTransactionFeeAtom,
  sbaGuarantyFeeAtom,
  sbaLoanTotalWithFeesWithoutLOCAtom,
  sellersNoteDollarAtom,
} from "@/financeModels/owasco/general";
import { MathResult } from "@/financeModels/types";
import { monospaceFontFamily } from "@/theme";
import { formatDecimalPercent, formatDollar } from "@/utils/format";
import { Table } from "@mantine/core";
import { useAtomValue } from "jotai";

export const Sources = () => {
  const sbaLoanDebt = useAtomValue(sbaLoanTotalWithFeesWithoutLOCAtom);
  const sellersNote = useAtomValue(sellersNoteDollarAtom);
  const equityDollar = useAtomValue(equityDollarAtom);
  const isRollinLoanTransactionFee = useAtomValue(
    isRollinLoanTransactionFeeAtom,
  );
  const isRollinSBAGuarantyFee = useAtomValue(isRollinSBAGuarantyFeeAtom);
  const loanTransactionFee = useAtomValue(loanTransactionFeeAtom);
  const sbaguarantyFee = useAtomValue(sbaGuarantyFeeAtom);
  const lineOfCredit = useAtomValue(lenderLineOfCreditTotalAmountAtom);

  const items: [string, MathResult][] = [
    ["SBA 7(a) Loan", sbaLoanDebt],
    ...(lineOfCredit
      ? [["Line of Credit (Working Capital)", lineOfCredit]]
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ([] as any)),
    ["Seller's Note", sellersNote],
    ["Equity", equityDollar],
  ];

  if (!isRollinLoanTransactionFee)
    items.push(["Loan Transaction Fee", loanTransactionFee]);

  if (!isRollinSBAGuarantyFee) items.push(["SBA Guaranty Fee", sbaguarantyFee]);

  const totalProjectCost = items.reduce(
    (acc, [, value]) => acc + Number(value),
    0,
  );

  return (
    <LayoutWorkstation.Panel p={0}>
      <Table striped>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Sources</Table.Th>
            <Table.Th ta={"right"} pr={30}>
              Amount
            </Table.Th>
            <Table.Th w={"10%"}></Table.Th>
            <Table.Th ta={"right"} w={90}>
              % of Total
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {items.map((item) => (
            <Table.Tr key={item[0]}>
              <Table.Td>{item[0]}</Table.Td>
              <Table.Td ta={"right"} ff={monospaceFontFamily}>
                {formatDollar(Number(item[1]))}
              </Table.Td>
              <Table.Td></Table.Td>
              <Table.Td ff={monospaceFontFamily}>
                {formatDecimalPercent(
                  Number(item[1]) / Number(totalProjectCost),
                )}
              </Table.Td>
            </Table.Tr>
          ))}

          <Table.Tr fw={700}>
            <Table.Td>Total Project Cost</Table.Td>
            <Table.Td ta={"right"} ff={monospaceFontFamily}>
              {formatDollar(totalProjectCost)}
            </Table.Td>
            <Table.Td></Table.Td>
            <Table.Td ff={monospaceFontFamily} fw={700}>
              100%
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </LayoutWorkstation.Panel>
  );
};
