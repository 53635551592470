import tinyMinusIcon from "@/assets/minus.png";
import tinyXIcon from "@/assets/multiply.png";
import tinyPlusIcon from "@/assets/plus.png";
import { CellTypes } from "@/financeModels/owasco/types";
import {
  ActionIcon,
  Box,
  BoxComponentProps,
  //Collapse,
  Group,
  GroupProps,
  Stack,
  Tooltip,
} from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { Atom, PrimitiveAtom } from "jotai";
import { CSSProperties, ReactNode } from "react";
import { LuListX, LuSigma } from "react-icons/lu";
import { TbListTree } from "react-icons/tb";
import { CellRow } from "./CellRow";
import { defaultHeight } from "./cell/Wrapper";

const pillColor = "var(--mantine-color-brand-6)";
export const lineSize = 7;
export const lineColor = "var(--mantine-color-brand-6)";
const dotSize = 11;
export const lineLeftBorderStyles = {
  borderLeft: `${lineSize}px solid ${lineColor}`,
} as const;

const Collapse = ({
  children,
  in: inProp,
  ...props
}: BoxComponentProps & {
  children: ReactNode;
  in: boolean;
}) => {
  return <Box {...props}>{inProp ? children : null}</Box>;
};

export const Waterfall = ({
  children,
  style = {},
}: {
  children: ReactNode;
  style?: React.CSSProperties;
}) => {
  return (
    <Stack gap={0} style={{ ...style }}>
      {children}
    </Stack>
  );
};

Waterfall.Offset = ({ children }: { children: ReactNode }) => {
  return <div style={{ transform: "translate(-20px, 0px)" }}>{children}</div>;
};

type DotVariant = "add" | "minus" | "multiply" | "blank" | "equals" | "cap";
Waterfall.Dot = ({
  children,
  variant = "add",
  ...props
}: GroupProps & {
  children?: ReactNode;
  variant?: DotVariant;
}) => {
  const size = dotSize;

  const bgImage = {
    blank: "",
    add: tinyPlusIcon,
    multiply: tinyXIcon,
    minus: tinyMinusIcon,
    equals: "",
    cap: "",
  }[variant];

  const shape = {
    blank: "polygon(64% 0%, 100% 50%, 64% 100%, 0% 100%, 0 54%, 0% 0%)",
    add: "polygon(64% 0%, 100% 50%, 64% 100%, 0% 100%, 0 54%, 0% 0%)",
    multiply: "polygon(64% 0%, 100% 50%, 64% 100%, 0% 100%, 0 54%, 0% 0%)",
    minus: "polygon(64% 0%, 100% 50%, 64% 100%, 0% 100%, 0 54%, 0% 0%)",
    cap: `polygon(0 ${2}px, ${dotSize}px ${2}px, ${dotSize}px ${
      lineSize + 2
    }px, 0 ${lineSize + 2}px)`,
    equals: `polygon(0 ${2}px, ${dotSize}px ${2}px, ${dotSize}px ${
      lineSize + 2
    }px, 0 ${lineSize + 2}px)`,
  }[variant];

  return (
    <Group gap={0} pos={"relative"} left={-8} {...props}>
      <div
        aria-hidden
        style={{
          width: size,
          height: size,
          clipPath: shape,
          position: "relative",
          left: -lineSize,
          backgroundImage: `url(${bgImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "1px center",
          backgroundColor: lineColor,
        }}
      ></div>

      {children}
    </Group>
  );
};

Waterfall.Pill = ({
  children,
  style = {},
}: {
  children: ReactNode;
  style?: CSSProperties;
}) => {
  return (
    <Group>
      <Group
        bg={pillColor}
        gap={6}
        px={6}
        align="center"
        justify="center"
        style={{
          borderRadius: 3,
          lineHeight: 1.6,
          height: 22,
          fontWeight: 600,
          ...style,
        }}
        c={"white"}
      >
        {children}
      </Group>
    </Group>
  );
};

Waterfall.Formula = ({ children }: { children: ReactNode }) => {
  return (
    <Waterfall.Pill
      style={{
        letterSpacing: 0.2,
      }}
    >
      <LuSigma size={14} />
      {children}
    </Waterfall.Pill>
  );
};

Waterfall.Stub = ({ children }: { children: ReactNode }) => {
  return (
    <div style={{ display: "flex" }}>
      <Box
        fz={"sm"}
        style={{
          height: defaultHeight,
          display: "flex",
          position: "sticky",
          left: 0,
          ...lineLeftBorderStyles,
        }}
        pl="xs"
      >
        {children}
      </Box>
    </div>
  );
};

Waterfall.Collapse = ({
  children,
  title,
  tabY,
  molecule,
  isRoot,
  dotVariant,
  id,
}: {
  children: ReactNode;
  title: ReactNode | string;
  tabY: number;
  molecule: Atom<PrimitiveAtom<CellTypes>[]> | Atom<Atom<CellTypes>[]>;
  isRoot?: boolean;
  dotVariant?: DotVariant;
  id?: string;
}) => {
  // if title is a string, use it as the id, otherwise use the id prop
  const storageId = id || typeof title === "string" ? title : id;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = useLocalStorage({
    key: "waterfall-collapse-" + storageId + "-" + tabY,
    defaultValue: false,
  });

  const ClosedLabelWrapper = isRoot ? Group : Waterfall.Dot;

  return (
    <>
      <Collapse in={open}>
        <Waterfall>
          <Group gap={0}>
            {!isRoot && (
              <div
                style={{
                  position: "sticky",
                  zIndex: 1,
                  left: 0,
                  width: 0,
                  //height: "100%",
                  //paddingLeft: 20,

                  alignSelf: "stretch",
                  justifySelf: "stretch",
                }}
              >
                <div>
                  <div
                    style={{
                      ...lineLeftBorderStyles,
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      background: "var(--mantine-color-gray-3)",
                      width: 20,
                    }}
                  ></div>
                </div>
              </div>
            )}
            <div
              style={{
                transform: isRoot ? "" : "translate(20px, 0px)",
                paddingTop: isRoot ? 0 : 20,
                paddingBottom: isRoot ? 0 : 20,
                boxShadow: `inset 0px 80px var(--mantine-color-gray-3),inset ${lineColor} ${lineSize}px 0px 0px 0px`,
              }}
            >
              {children}
            </div>
          </Group>

          <CellRow
            tabY={tabY}
            molecule={molecule}
            label={
              <Group gap={4} pos={"relative"}>
                <Waterfall.Dot
                  variant={dotVariant || "equals"}
                  pos={"relative"}
                  //left={isRoot ? 0 : -27}
                >
                  <Group gap={4} pos={"relative"} left={isRoot ? 0 : -4}>
                    {!isRoot && (
                      <div
                        style={{
                          ...lineLeftBorderStyles,
                          height: 16,
                          position: "absolute",
                          left: 6,
                          top: -14,
                          //borderColor: "red",
                        }}
                      ></div>
                    )}
                    <Waterfall.Formula>{title}</Waterfall.Formula>
                    <Tooltip label="Collapse Calculation">
                      <ActionIcon
                        size={"sm"}
                        color="accent.4"
                        onClick={() => setOpen((o) => !o)}
                      >
                        <LuListX />
                      </ActionIcon>
                    </Tooltip>
                  </Group>
                </Waterfall.Dot>
              </Group>
            }
          />
        </Waterfall>
      </Collapse>

      <Collapse in={!open}>
        <CellRow
          tabY={tabY}
          molecule={molecule}
          label={
            <ClosedLabelWrapper
              variant={dotVariant || "blank"}
              //pl={isRoot ? 6 : 0}
            >
              <Group gap={4} justify="space-between">
                <Waterfall.Formula>{title}</Waterfall.Formula>
                <Tooltip label="Expand Calculation" withArrow>
                  <ActionIcon
                    size={"sm"}
                    variant="light"
                    color="accent.4"
                    onClick={() => setOpen((o) => !o)}
                  >
                    <TbListTree />
                  </ActionIcon>
                </Tooltip>
              </Group>
            </ClosedLabelWrapper>
          }
        />
      </Collapse>
    </>
  );
};
