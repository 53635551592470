import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import {
  AmortizationPayment,
  getAmoritizationNumberPayments,
} from "@/financeModels/owasco/amortization";
import { formatDecimalPercent, formatDollar } from "@/utils/format";
import { Group, Stack, Table } from "@mantine/core";
import { css } from "vite-plugin-inline-css-modules";

export type LoanTableProps = {
  loanAmount: number | null;
  interestRate: number | null;
  paymentsPerYear: number | null;
  schedule: number | null;
  loanStartDate: Date;
  payments: AmortizationPayment[];
};

const classes = css`
  td:last-child {
    text-align: right;
    font-weight: 500;
  }
`;

export const LoanTable = ({
  loanAmount,
  interestRate,
  paymentsPerYear,
  loanStartDate,
  payments,
  schedule,
}: LoanTableProps) => {
  const yearCount = Math.floor(schedule || 0);
  const monthCount = Math.round(((schedule || 0) % 1) * 12);
  const loanEndDate = payments.at(-1)?.date;
  const plannedSheduledPaymentsCount = getAmoritizationNumberPayments({
    schedule: schedule || 0,
    paymentPerYear: paymentsPerYear || 0,
  });
  const actualScheduledPaymentsCount = payments.length;
  const totalExtraPayments = payments.reduce(
    (acc, payment) => acc + (payment.fixedExtraPayment || 0),
    0,
  );

  return (
    <Group justify="center" classNames={classes}>
      <Stack gap="xs">
        <LayoutWorkstation.Panel label={"Loan Details"}>
          <Table miw={300}>
            <Table.Tbody>
              <Table.Tr>
                <Table.Td>Loan Amount</Table.Td>
                <Table.Td ta={"left"}>{formatDollar(loanAmount || 0)}</Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>Interest Rate</Table.Td>
                <Table.Td ta={"left"}>
                  {formatDecimalPercent(interestRate)}
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>Loan Period</Table.Td>
                <Table.Td ta={"left"}>
                  {Number(yearCount)} years{" "}
                  {monthCount > 0 && <>, {Number(monthCount)} months</>}
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>Loan Start Date</Table.Td>
                <Table.Td ta={"left"}>
                  {loanStartDate.toLocaleDateString()}
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>Loan Payoff Date</Table.Td>
                <Table.Td ta={"left"}>
                  {loanEndDate && loanEndDate.toLocaleDateString()}
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>
        </LayoutWorkstation.Panel>
      </Stack>
      <Stack gap="xs">
        <LayoutWorkstation.Panel label={"Repayment Summary"} miw={480}>
          <Table>
            <Table.Tbody>
              <Table.Tr>
                <Table.Td>Scheduled Monthly Payment</Table.Td>
                <Table.Td ta={"left"}>
                  {formatDollar(Number(payments.at(-2)?.scheduledPayment))}
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>Scheduled Periods</Table.Td>
                <Table.Td ta={"left"}>
                  {plannedSheduledPaymentsCount} monthly payments
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>Actual Number of Payments</Table.Td>
                <Table.Td ta={"left"}>
                  {actualScheduledPaymentsCount} monthly payments
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>Total Extra Payments </Table.Td>
                <Table.Td ta={"left"}>
                  {formatDollar(totalExtraPayments || 0)}
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>Total Interest</Table.Td>
                <Table.Td ta={"left"}>
                  {formatDollar(payments.at(-1)?.cumulativeInterest || 0)}
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>
        </LayoutWorkstation.Panel>
      </Stack>
    </Group>
  );
};
