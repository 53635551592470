import { evaluteWithErrors as solve, toNumberOrNull } from "@/utils/math";
import { getMoleculeValueAtIndex } from "@/utils/molecule";
import { atom } from "jotai";
import { DollarAmount, PercentAmount } from "../types";
import {
  AmortizationPayment,
  sbaLoanAmortizationPaymentsAtom,
  sellersNoteAmortizationPaymentsAtom,
} from "./amortization";
import {
  createComputedCellMoleculeFromEpochs,
  createStorableDollarMoleculeFromEpochs,
} from "./epochMolecule";
import {
  buyerAnnualCapExAtom,
  buyerCompTotalAtom,
  buyerDeprAndAmortScheduleAtom,
  companysTaxRateAtom,
  lenderLineOfCreditDayOneDrawdownAtom,
  sbaLoanInterestRateAtom,
  targetPurchasePriceAtom,
  totalDealExpensesAtom,
} from "./general";
import {
  createAggregatedDollarItemizationAtom,
  getSummaryValueAtIndex,
} from "./itemization";

////
// Company Financials
////
export const grossRevenueItemization = createAggregatedDollarItemizationAtom(
  "lookback|grossRevenue",
);

export const costOfGoodsSoldItemization = createAggregatedDollarItemizationAtom(
  "lookback|costOfGoodsSold",
  { isNegative: true },
);

export const grossProfitMolecule = createComputedCellMoleculeFromEpochs(
  (get, i) => ({
    type: "dollar",
    isLocked: true,
    value: solve<DollarAmount>("grossRevenue - costOfGoodsSold", {
      grossRevenue: getSummaryValueAtIndex(grossRevenueItemization, i, get),
      costOfGoodsSold:
        getSummaryValueAtIndex(costOfGoodsSoldItemization, i, get) || 0,
    }),
  }),
);

export const grossMarginsMolecule = createComputedCellMoleculeFromEpochs(
  (get, i) => ({
    type: "percent",
    isLocked: true,
    value: solve<PercentAmount>("grossProfit / grossRevenue", {
      grossProfit: get(get(grossProfitMolecule)[i]).value,
      grossRevenue: getSummaryValueAtIndex(grossRevenueItemization, i, get),
    }),
  }),
);

export const operatingExpensesItemization =
  createAggregatedDollarItemizationAtom("lookbackOperatingExpenses", {
    isNegative: true,
  });

export const otherIncomeMolecule = createStorableDollarMoleculeFromEpochs(
  "lookbackOtherIncome",
);

export const netIncomeMolecule = createComputedCellMoleculeFromEpochs(
  (get, i) => ({
    type: "dollar",
    isLocked: true,
    value: solve<DollarAmount>(
      "grossProfit - operatingExpenses + otherIncome",
      {
        grossProfit: get(get(grossProfitMolecule)[i]).value,
        operatingExpenses:
          getSummaryValueAtIndex(operatingExpensesItemization, i, get) || 0,
        otherIncome: get(get(otherIncomeMolecule)[i]).value || 0,
      },
    ),
  }),
);

////
// Seller's Financial Adjustments
////

export const sellersInterestExpenseMolecule =
  createStorableDollarMoleculeFromEpochs("lookbackSellersInterestExpense");

export const sellersDeprAmortMolecule = createStorableDollarMoleculeFromEpochs(
  "lookbackSellersDeprAmort",
);

export const sellersIncomeTaxMolecule = createStorableDollarMoleculeFromEpochs(
  "lookbackSellersIncomeTax",
);

export const sellersEBITDAMolecule = createComputedCellMoleculeFromEpochs(
  (get, i) => ({
    type: "dollar",
    isLocked: true,
    value: solve<DollarAmount>(
      "netIncome + sellersInterestExpense + sellersDeprAmort + sellersIncomeTax",
      {
        netIncome: get(get(netIncomeMolecule)[i]).value,
        sellersInterestExpense:
          get(get(sellersInterestExpenseMolecule)[i]).value || 0,
        sellersDeprAmort: get(get(sellersDeprAmortMolecule)[i]).value || 0,
        sellersIncomeTax: get(get(sellersIncomeTaxMolecule)[i]).value || 0,
      },
    ),
  }),
);

export const sellersEBITDAMarginsMolecule =
  createComputedCellMoleculeFromEpochs((get, i) => ({
    type: "percent",
    isLocked: true,
    value: solve<PercentAmount>("sellersEBITDA / grossRevenue", {
      sellersEBITDA: get(get(sellersEBITDAMolecule)[i]).value,
      grossRevenue: getSummaryValueAtIndex(grossRevenueItemization, i, get),
    }),
  }));

export const addbacksAndAdjustmentsItemization =
  createAggregatedDollarItemizationAtom("lookbackAddbacksAndAdjustments");

export const sellersDiscretionaryEarningsMolecule =
  createComputedCellMoleculeFromEpochs((get, i) => ({
    type: "dollar",
    isLocked: true,
    value: solve<DollarAmount>("sellersEBITDA + addbacksAndAdjustments", {
      sellersEBITDA: get(get(sellersEBITDAMolecule)[i]).value,
      addbacksAndAdjustments:
        getSummaryValueAtIndex(addbacksAndAdjustmentsItemization, i, get) || 0,
    }),
  }));

export const sellersDiscretionaryEarningsMarginsMolecule =
  createComputedCellMoleculeFromEpochs((get, i) => ({
    type: "percent",
    isLocked: true,
    value: solve<PercentAmount>("sellersDiscretionaryEarnings / grossRevenue", {
      sellersDiscretionaryEarnings: get(
        get(sellersDiscretionaryEarningsMolecule)[i],
      ).value,
      grossRevenue: getSummaryValueAtIndex(grossRevenueItemization, i, get),
    }),
  }));

////
// Buyer's Pro Forma Adjustments
////

export const buyersCompensationMolecule = createComputedCellMoleculeFromEpochs(
  (get) => ({
    type: "dollar",
    isLocked: true,
    isNegative: true,
    value: get(buyerCompTotalAtom),
  }),
);

export const newOperatingExpensesItemization =
  createAggregatedDollarItemizationAtom("lookbackNewOperatingExpenses", {
    isNegative: true,
  });

export const buyersAdjustedEBITDAMolecule =
  createComputedCellMoleculeFromEpochs((get, i) => ({
    type: "dollar",
    isLocked: true,
    value: solve<DollarAmount>(
      "sellersDiscretionaryEarnings - buyersCompensation - newOperatingExpenses",
      {
        sellersDiscretionaryEarnings: get(
          get(sellersDiscretionaryEarningsMolecule)[i],
        ).value,
        buyersCompensation: get(buyerCompTotalAtom),
        newOperatingExpenses:
          getSummaryValueAtIndex(newOperatingExpensesItemization, i, get) || 0,
      },
    ),
  }));

export const buyersAdjustedEBITDAMarginsMolecule =
  createComputedCellMoleculeFromEpochs((get, i) => ({
    type: "percent",
    isLocked: true,
    value: solve<PercentAmount>("buyersAdjustedEBITDA / grossRevenue", {
      buyersAdjustedEBITDA: get(get(buyersAdjustedEBITDAMolecule)[i]).value,
      grossRevenue: getSummaryValueAtIndex(grossRevenueItemization, i, get),
    }),
  }));

////
// Amortization Expense Calculations
////
const calcOneYearAmortization = (payments: AmortizationPayment[]) => {
  let interest = 0;
  let principal = 0;
  let total = 0;

  // only first 12 payments
  for (let i = 0; i < 12; i++) {
    const payment = payments[i];
    interest += payment?.interest || 0;
    principal += payment?.principal || 0;
    total += (payment?.interest || 0) + (payment?.principal || 0);
  }

  return {
    interest,
    principal,
    total,
  };
};

export const sbaLoanAmortOneYearAtom = atom((get) =>
  calcOneYearAmortization(get(sbaLoanAmortizationPaymentsAtom)),
);

export const sellersNoteAmortOneYearAtom = atom((get) =>
  calcOneYearAmortization(get(sellersNoteAmortizationPaymentsAtom)),
);

export const sba7aLoanExpenseInterestMolecule =
  createComputedCellMoleculeFromEpochs((get) => ({
    type: "dollar",
    isLocked: true,
    isNegative: true,
    value: get(sbaLoanAmortOneYearAtom).interest,
  }));

export const sellersNoteExpenseInterestMolecule =
  createComputedCellMoleculeFromEpochs((get) => ({
    type: "dollar",
    isLocked: true,
    isNegative: true,
    value: get(sellersNoteAmortOneYearAtom).interest,
  }));

export const lineOfCreditInterestMolecule =
  createComputedCellMoleculeFromEpochs((get) => ({
    type: "dollar",
    isLocked: true,
    value: solve<DollarAmount>(
      "lenderLineOfCreditDayOneDrawdown * sbaLoanInterestRate",
      {
        lenderLineOfCreditDayOneDrawdown: get(
          lenderLineOfCreditDayOneDrawdownAtom,
        ),
        sbaLoanInterestRate: get(sbaLoanInterestRateAtom),
      },
    ),
  }));

export const sba7aLoanExpensePricipalMolecule =
  createComputedCellMoleculeFromEpochs((get) => ({
    type: "dollar",
    isLocked: true,
    isNegative: true,
    value: get(sbaLoanAmortOneYearAtom).principal,
  }));

export const sellersNoteExpensePricipalMolecule =
  createComputedCellMoleculeFromEpochs((get) => ({
    type: "dollar",
    isLocked: true,
    isNegative: true,
    value: get(sellersNoteAmortOneYearAtom).principal,
  }));

export const lineOfCreditPricipalMolecule =
  createComputedCellMoleculeFromEpochs(() => ({
    type: "dollar",
    isLocked: true,
    isNegative: true,
    value: 0,
  }));

export const sba7aLoanExpenseTotalMolecule =
  createComputedCellMoleculeFromEpochs((get) => ({
    type: "dollar",
    isLocked: true,
    isNegative: true,
    value: get(sbaLoanAmortOneYearAtom).total,
  }));

export const sellersNoteExpenseTotalMolecule =
  createComputedCellMoleculeFromEpochs((get) => ({
    type: "dollar",
    isLocked: true,
    isNegative: true,
    value: get(sellersNoteAmortOneYearAtom).total,
  }));

////
// Buyer's Pro Forma Financials
////

export const annualDeprAmortizationExpensesAtom = atom<DollarAmount>((get) =>
  solve<DollarAmount>("purchasePrice / deprAndAmortSchedule", {
    purchasePrice: get(targetPurchasePriceAtom) || 0,
    deprAndAmortSchedule: get(buyerDeprAndAmortScheduleAtom),
  }),
);

export const annualDeprAmortizationExpensesMolecule =
  createComputedCellMoleculeFromEpochs((get) => ({
    type: "dollar",
    isLocked: true,
    isNegative: true,
    value: get(annualDeprAmortizationExpensesAtom),
  }));

export const amortOfDealExpensesAtom = atom<DollarAmount>((get) =>
  solve<DollarAmount>("dealExpenses / 15", {
    dealExpenses: get(totalDealExpensesAtom),
  }),
);

export const amortOfDealExpensesMolecule = createComputedCellMoleculeFromEpochs(
  (get) => ({
    type: "dollar",
    isLocked: true,
    isNegative: true,
    value: get(amortOfDealExpensesAtom),
  }),
);

export const taxableIncomeMolecule = createComputedCellMoleculeFromEpochs(
  (get, i) => ({
    type: "dollar",
    isLocked: true,
    isNegative: true,
    value: solve<DollarAmount>(
      "max(0, buyersAdjustedEBITDA - interestExpenseSeniorTermLoan - interestExpenseSellersNote - annualDeprAmortizationExpenses - annualMaintenanceCapEx - amortOfDealExpenses)",
      {
        buyersAdjustedEBITDA: get(get(buyersAdjustedEBITDAMolecule)[i]).value,
        interestExpenseSeniorTermLoan: get(
          get(sba7aLoanExpenseInterestMolecule)[i],
        ).value,
        interestExpenseSellersNote: get(
          get(sellersNoteExpenseInterestMolecule)[i],
        ).value,
        annualDeprAmortizationExpenses: get(annualDeprAmortizationExpensesAtom),
        annualMaintenanceCapEx: get(buyerAnnualCapExAtom) || 0,
        amortOfDealExpenses: get(amortOfDealExpensesAtom),
      },
    ),
  }),
);

export const companysTaxRateMolecule = createComputedCellMoleculeFromEpochs(
  (get) => ({
    type: "percent",
    isLocked: true,
    value: get(companysTaxRateAtom),
  }),
);

export const incomeTaxesMolecule = createComputedCellMoleculeFromEpochs(
  (get, i) => ({
    type: "dollar",
    isLocked: true,
    isNegative: true,
    value: solve<DollarAmount>(
      "bankRound(max( 0, taxableIncome * buyerTaxRate))",
      {
        taxableIncome: get(get(taxableIncomeMolecule)[i]).value,
        buyerTaxRate: get(companysTaxRateAtom),
      },
    ),
  }),
);

export const annualMaintenanceCapExMolecule =
  createComputedCellMoleculeFromEpochs((get) => ({
    type: "dollar",
    isLocked: true,
    isNegative: true,
    value: get(buyerAnnualCapExAtom),
  }));

export const unleveredFreeCashFlowMolecule =
  createComputedCellMoleculeFromEpochs((get, i) => ({
    type: "dollar",
    isLocked: true,
    value: solve<DollarAmount>(
      "bankRound(buyersAdjustedEBITDA - incomeTaxes - annualMaintenanceCapEx)",
      {
        buyersAdjustedEBITDA: get(get(buyersAdjustedEBITDAMolecule)[i]).value,
        incomeTaxes: get(get(incomeTaxesMolecule)[i]).value,
        annualMaintenanceCapEx: get(buyerAnnualCapExAtom) || 0,
      },
    ),
  }));

export const totalDebtServiceMolecule = createComputedCellMoleculeFromEpochs(
  (get, i) => ({
    type: "dollar",
    isLocked: true,
    isNegative: true,
    value: solve<DollarAmount>(
      "sba7aLoanExpenseTotal + sellersNoteExpenseTotal + lineOfCreditInterest",
      {
        sba7aLoanExpenseTotal:
          toNumberOrNull(
            getMoleculeValueAtIndex(get, sba7aLoanExpenseTotalMolecule, i, 0),
          ) || 0,
        sellersNoteExpenseTotal:
          toNumberOrNull(
            getMoleculeValueAtIndex(get, sellersNoteExpenseTotalMolecule, i, 0),
          ) || 0,
        lineOfCreditInterest:
          toNumberOrNull(
            getMoleculeValueAtIndex(get, lineOfCreditInterestMolecule, i, 0),
          ) || 0,
      },
    ),
  }),
);

export const distributableFreeCashFlowMolecule =
  createComputedCellMoleculeFromEpochs((get, i) => ({
    type: "dollar",
    isLocked: true,
    value: solve<DollarAmount>(
      "bankRound(unleveredFreeCashFlow - totalDebtService)",
      {
        unleveredFreeCashFlow: getMoleculeValueAtIndex(
          get,
          unleveredFreeCashFlowMolecule,
          i,
          null,
        ),
        totalDebtService: getMoleculeValueAtIndex(
          get,
          totalDebtServiceMolecule,
          0,
          null,
        ),
      },
    ),
  }));

// TODO
// Equity Investor Distributions = (just hardcode $0 for this right now – waiting for some feedback from an accountant friend on how this should be treated)
export const equityInvestorDistributionsMolecule =
  createComputedCellMoleculeFromEpochs(() => ({
    type: "dollar",
    isLocked: true,
    value: 0,
  }));

export const useableFreeCashFlowMolecule = createComputedCellMoleculeFromEpochs(
  (get, i) => ({
    type: "dollar",
    isLocked: true,
    value: solve<DollarAmount>(
      "distributableFreeCashFlow - equityInvestorDistributions",
      {
        distributableFreeCashFlow: get(
          get(distributableFreeCashFlowMolecule)[i],
        ).value,
        equityInvestorDistributions: get(
          get(equityInvestorDistributionsMolecule)[i],
        ).value,
      },
    ),
  }),
);

export const dscrMolecule = createComputedCellMoleculeFromEpochs((get, i) => ({
  type: "dollar",
  isLocked: true,
  value: solve<DollarAmount>("abs(unleveredFreeCashFlow / totalDebtPayment)", {
    unleveredFreeCashFlow: get(get(unleveredFreeCashFlowMolecule)[i]).value,
    totalDebtPayment: get(get(totalDebtServiceMolecule)[i]).value,
  }),
}));
