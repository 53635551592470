import { ActionIcon, BoxComponentProps, Tooltip } from "@mantine/core";
import { ReactNode, useEffect, useState } from "react";
import { RiRefreshFill } from "react-icons/ri";
import { selectActiveElement } from "../hooks/useTabCoordinates";
import classes from "./Cell.module.css";

const arrowKeys = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"];

export const Stub = ({
  enableEditing,
  label,
  moveFocus,
  resetCell,
  ta,
  locked,
  overrided,
  animationKey,
  bg = "white",
  blank,
  startEditRegex = /\d/,
  justifyContent = "right",
}: BoxComponentProps & {
  label: ReactNode;
  resetCell?: () => void;
  enableEditing: (value?: unknown) => void;
  moveFocus: (dir: "up" | "down" | "left" | "right") => void;
  ta?: "left" | "right" | "center";
  locked?: boolean;
  overrided?: boolean;
  blank?: boolean;
  animationKey?: string;
  startEditRegex?: RegExp;
  justifyContent?: "left" | "right" | "center";
}) => {
  const [animationKeyCount, setAnimationKeyCount] = useState<number>(-3);

  if (overrided) bg = `var(--mantine-color-blue-0) `;
  if (locked)
    bg =
      "linear-gradient(45deg, #f5f5f5 25%, #f9f9f9 25%, #f9f9f9 50%, #f5f5f5 50%, #f5f5f5 75%, #f9f9f9 75%, #f9f9f9 100%) 0 0 / 20px 20px";
  if (blank) bg = "#EBEBEE";

  useEffect(() => {
    if (animationKey === undefined) return;
    setAnimationKeyCount((c) => (c >= 1 ? 0 : c + 1));
  }, [animationKey, setAnimationKeyCount]);

  return (
    <div
      tabIndex={0}
      style={{
        //...props.style,
        height: "100%",
        width: "100%",
        position: "relative",
        userSelect: "none",
        overflow: "hidden",
        textAlign: ta,
        display: "flex",
        justifyContent,
        alignItems: "center",
        background: bg as string,
        padding: "0 8px",
        //...(blank ? { boxShadow: "inset 0 0 0 1px #DEE2E6" } : {}),
      }}
      className={[
        "stub-cell",
        `flash-${animationKeyCount}`,
        classes.cell,
        locked ? classes.locked : "",
      ].join(" ")}
      onDoubleClick={() => {
        enableEditing();
        selectActiveElement();
      }}
      onKeyDown={(e) => {
        if (arrowKeys.includes(e.key)) {
          e.preventDefault();
          moveFocus(
            e.key.replace("Arrow", "").toLowerCase() as
              | "up"
              | "down"
              | "left"
              | "right",
          );
          return;
        }

        if (locked) return;

        if (e.key === "Enter") {
          enableEditing();
          selectActiveElement();
        }

        if (e.key.match(startEditRegex)) {
          enableEditing();
        }

        if (e.key === "Delete") {
          resetCell?.();
        }

        if (e.key === "Backspace") {
          enableEditing(null);
        }
      }}
    >
      {overrided && (
        <Tooltip
          label="Reset to the auto-calculated value based on 'Growth Rate (%)'"
          withArrow
        >
          <ActionIcon
            size={"xs"}
            variant="transparent"
            color="blue"
            onClick={resetCell}
            pos={"absolute"}
            left={2}
            radius={99}
          >
            <RiRefreshFill size={20} />
          </ActionIcon>
        </Tooltip>
      )}
      {label}
    </div>
  );
};
