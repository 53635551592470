import { RenderWhenInView } from "@/components/RenderWhenInView/RenderWhenInView";
import { AmortizationPayment } from "@/financeModels/owasco/amortization";
import { PaymentMeta } from "@/financeModels/owasco/types";
import { MathResult } from "@/financeModels/types";
import { monospaceFontFamily, textFontFamily } from "@/theme";
import { formatDollar } from "@/utils/format";
import { toNumberOrNull } from "@/utils/math";
import { Button, ScrollArea, Stack } from "@mantine/core";
import { PrimitiveAtom, useAtomValue } from "jotai";
import { CSSProperties, ReactNode, memo, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { ExtraPaymentCell } from "./ExtraPaymentCell";
import { ExtraPaymentsMenu } from "./ExtraPaymentsMenu";

const rowHeight = 36;
const largeNumberWidth = 180;
const mediumNumberWidth = 156;

const columns: {
  label: React.ReactNode;
  id: string;
  style: CSSProperties;
}[] = [
  {
    label: undefined,
    id: "paymentPeriod",
    style: {
      width: 44,
    },
  },
  {
    label: "Date",
    id: "date",
    style: {
      width: 114,
    },
  },
  {
    label: "Year",
    id: "yearPeriod",
    style: {
      width: 54,
    },
  },
  {
    label: "Extra Payment",
    id: "extraPayment",
    style: {
      minWidth: mediumNumberWidth,
      flex: 1,
    },
  },
  {
    label: "Total Payment",
    id: "totalPayment",
    style: {
      minWidth: mediumNumberWidth,
      flex: 1,
    },
  },
  {
    label: "Scheduled Payment",
    id: "scheduledPayment",
    style: {
      minWidth: mediumNumberWidth,
      flex: 1,
    },
  },
  {
    label: "Principal",
    id: "principal",
    style: {
      minWidth: mediumNumberWidth,
      flex: 1,
    },
  },
  {
    label: "Interest",
    id: "interest",
    style: {
      minWidth: mediumNumberWidth,
      flex: 1,
    },
  },
  {
    label: "Ending Balance",
    id: "endingBalance",
    style: {
      minWidth: largeNumberWidth,
      flex: 1,
    },
  },
  {
    label: "Cumulative Interest",
    id: "cumulativeInterest",
    style: {
      minWidth: mediumNumberWidth,
      flex: 1,
    },
  },
] as const;

type RowColumn = Record<
  (typeof columns)[number]["id"],
  React.ReactNode | CSSProperties
>;

export const AmortizationTable = ({
  payments,
  paymentsMetaAtom,
  recurringPaymentAtom,
}: {
  payments: AmortizationPayment[];
  paymentsMetaAtom: PrimitiveAtom<Record<number, PaymentMeta>>;
  recurringPaymentAtom: PrimitiveAtom<MathResult>;
}) => {
  const [activeExtraPaymentIndex, setActiveExtraPaymentIndex] = useState(-1);
  const [activeExtraPaymentsMenu, setActiveExtraPaymentsMenu] = useState(false);
  const recurringPayment = useAtomValue(recurringPaymentAtom);
  const submitTriggerRef = useRef<() => void>(() => {});

  return (
    <Stack
      gap={0}
      w={"100%"}
      mah={"100%"}
      h={"100%"}
      style={{
        borderBottom: "1px solid #e0e0e0",
      }}
    >
      <ScrollArea
        style={{
          flex: "1 1 auto",
          height: 0,
        }}
        onScrollPositionChange={() => {
          submitTriggerRef.current();
        }}
      >
        <Stack
          gap={0}
          style={{
            position: "sticky",
            top: 0,
            background: "#DEE2E6",
            paddingTop: 24,
          }}
        >
          <Row
            index={0}
            style={{
              background: "white",
              fontFamily: textFontFamily,
              fontSize: 14,
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              borderTop: "1px solid #e0e0e0",
              fontWeight: "500",
            }}
            {...columns.reduce(
              (acc, { id }) => ({
                ...acc,
                [id]: columns.find((c) => c.id === id)?.label,
              }),
              {},
            )}
            extraPayment={
              <div
                style={{
                  position: "relative",
                  width: "100%",
                }}
              >
                <ExtraPaymentsMenu
                  recurringPaymentAtom={recurringPaymentAtom}
                  isActive={activeExtraPaymentsMenu}
                  onToggle={() =>
                    setActiveExtraPaymentsMenu(!activeExtraPaymentsMenu)
                  }
                  onComplete={() => setActiveExtraPaymentsMenu(false)}
                >
                  <Button
                    size="compact-xs"
                    style={{
                      borderTopLeftRadius: 4,
                      borderTopRightRadius: 4,
                      position: "absolute",
                      left: "50%",
                      transform: "translateX(-50%)",
                      top: -28,
                    }}
                  >
                    <FaPlus style={{ marginRight: 4 }} />
                    {recurringPayment
                      ? formatDollar(toNumberOrNull(recurringPayment) || 0)
                      : "Recurring Extra Payments"}
                  </Button>
                </ExtraPaymentsMenu>
                Extra Payment
              </div>
            }
          />
        </Stack>
        {payments.map((payment, index) => (
          <RenderWhenInView key={index} height={rowHeight}>
            <Row
              style={{
                background: index % 2 === 0 ? "#fafafa" : "#f0f0f0",
              }}
              index={index}
              paymentPeriod={payment.paymentPeriod}
              date={payment.date.toLocaleDateString()}
              yearPeriod={payment.yearPeriod}
              extraPayment={
                <ExtraPaymentCell
                  index={index}
                  paymentsMetaAtom={paymentsMetaAtom}
                  isEditing={activeExtraPaymentIndex === index}
                  onSelect={() => setActiveExtraPaymentIndex(index)}
                  onComplete={() => setActiveExtraPaymentIndex(-1)}
                  submitTriggerRef={submitTriggerRef}
                >
                  {formatDollar(payment.fixedExtraPayment)}
                </ExtraPaymentCell>
              }
              totalPayment={formatDollar(payment.totalPayment)}
              principal={formatDollar(payment.principal)}
              interest={formatDollar(payment.interest)}
              endingBalance={formatDollar(payment.endingBalance)}
              cumulativeInterest={formatDollar(payment.cumulativeInterest)}
              scheduledPayment={formatDollar(payment.scheduledPayment)}
            />
          </RenderWhenInView>
        ))}
        <div style={{ height: 300 }}></div>
      </ScrollArea>
    </Stack>
  );
};

const Row = memo(
  ({
    style = {},
    ...row
  }: RowColumn & {
    style?: CSSProperties;
  }) => (
    <div
      style={{
        display: "flex",
        textAlign: "center",
        fontFamily: monospaceFontFamily,
        fontSize: "small",
        whiteSpace: "nowrap",
        height: rowHeight,
        ...style,
      }}
    >
      {columns.map(({ id }) => {
        const col = columns.find((c) => c.id === id);

        return (
          <div
            key={id}
            style={{
              borderRight: "1px solid #e0e0e0",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              ...col?.style,
            }}
          >
            {row[id] as ReactNode}
          </div>
        );
      })}
    </div>
  ),
);
